// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// import '@/stylesheets/button';
// import 'remixicon/fonts/remixicon.css';
// import '@/stories/modal.css';
// import '@/stylesheets/page.css';
// import '@/stylesheets/loader.css';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import ahoy from "ahoy.js"
require("../stylesheets/application.css")
require("../stylesheets/button.css")
require("../stylesheets/modal.css")
require("leaflet/dist/leaflet.css")
require("rc-slider/assets/index.css")

Rails.start();
Turbolinks.start();
ActiveStorage.start();
ahoy.configure({
    visitsUrl: "/steps/visits",
    eventsUrl: "/steps/events"
});

ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");


// We are "offline".
window.addEventListener("offline", () => {
  // Show "No Internet Connection." message.
  document.body.className = "offline";
});


// When we are back "online".
window.addEventListener("online", () => {
  // Show "Back online" message.
  document.body.className = "online";

  // Hide message after 800ms.
  setTimeout(() => { document.body.className = ""; }, 800);
});
